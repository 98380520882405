<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 5</h1>
      <h3>Functional Analysis</h3>
      <p class="text-left">Step 1: Protein Translation<br/>
      Step 2: Function Annotation</p>
      
      <p class="m-0 font-weight-bold">Select the folder which contains the relevant data for analysis:</p>
      <b-row><b-col>
        <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
          <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
        </b-alert>
      </b-col></b-row>
    
      <b-form @submit.prevent="submitJob">
        <folder-browser :folders="folders" :selectedFolder="selectedFolder"
          @select-folder="folderSelected">
        </folder-browser>

        <p class="m-0 text-left">Parameters:
          <ul>
            <li>Minimum Amino-Acid Number of ORF:Minimum number of amino-acids an open reading frame (ORF) must have to be considered as a potential CDS.</li>
            <li>Minimum Amino-Acid Number of Peptide: Minimum number of amino-acids a translation must have to be consider a peptide.</li>
            <li>CDS Length Percent of PTC: Minimum CDS length percentage below which a transcript is considered prematurely terminated.</li>
          </ul>
        </p>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Minimum Amino-Acid Number of ORF:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="orfTip">
              <b-form-input id="orfInput" v-model="orf" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Minimum amino-acid number of peptide:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="peptideTip">
              <b-form-input id="peptideInput" v-model="peptide" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-row class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">CDS length percent of PTC:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box " v-b-tooltip.hover.html :title="ptcTip">
              <b-form-input id="ptcInput" v-model="ptc" type="number" min="0" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-form-checkbox
          id="NameCheck"
          v-model="nameCheck"
          name="NameCheck"
        >
          Tick if you want to use a custom name for the results folder.
        </b-form-checkbox>

        <b-row v-if="nameCheck" class="input-row">
          <b-input-group>
            <b-input-group-prepend class="input-label">
              <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
            </b-input-group-prepend>
            <div class="input-box">
              <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
            </div>
          </b-input-group>
        </b-row>

        <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
      </b-form>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: "assembly",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      folderID: null,
      selectedFolder: {
        name: null,
        files: []
      },
      orf: 30,
      peptide: 100,
      ptc: 70,
      
      orfTip: "Minimum number of amino-acids an open reading frame (ORF) must have to be considered as a potential CDS.",
      peptideTip: "Minimum number of amino-acids a translation must have to be consider a peptide.",
      ptcTip: "Minimum CDS length percentage below which a transcript is considered prematurely terminated.",
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=lr5",
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        if(this.$route.params.resubmitData.addSourceFolder === "") {
          this.folderID = this.$route.params.resubmitData.filterFolder;
        }
        else {
          this.folderID = this.$route.params.resubmitData.addSourceFolder;
        }
        
        this.orf = this.$route.params.resubmitData.orf;
        this.peptide = this.$route.params.resubmitData.peptide;
        this.ptc = this.$route.params.resubmitData.ptc;

        if(this.folderID !== undefined && this.folderID !== null)
        {
          this.folders.forEach(folder => {
            if((folder.id.localeCompare(this.folderID) == 0))
            {
              this.folderSelected(folder);
            }
          });
        }
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/longread/analysis", {
        params: {
          token: this.token,
          workingFolderID: this.selectedFolder.id,
          orf: this.orf,
          peptide: this.peptide,
          ptc: this.ptc,
          name: custName

        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.folderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
</style>